import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Blog from '../../components/blog/en'
import LeftColumn from '../../components/blog/left-column/en'
import RightColumn from '../../components/blog/right-column/en'
import PostSummary from '../../components/blog/post-summary/en'
import PostIndex from '../../components/blog/post-index/en'
import ether from './Ethereum_logo_2014.svg'
import messages from '../../data/messages/en';

export default (props) => (
    <StaticQuery
    query={graphql`

    query {

      logo: file(relativePath: {eq: "components/blog/author/logo-manu.png"}) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            base64
            src
            width
            height
          }
        }
      }


    btc: file(relativePath: {eq: "pages/contact/BC_Logo_.png"}) {
            childImageSharp {
              fixed(width: 100, height: 100) {
                base64
                src
                width
                height
              }
            }
          }



    }


    `}

      render={
        data => {
          console.log(props);
          return (
       <Blog  {...props}>
    <LeftColumn {...props} >

      <div  className="card mb-4">
        <div className="card-body">
          <a className="entries-link" href={props.url} >
            <h1 className="text-center"> {messages.title} </h1>
            <h2 className="text-center entries-title"> {messages.subTitle}</h2>
          </a>
          <p className="text-justify p-2">
            Thanks for visiting my website. Drop me a line if you think I can help your project. I have experience in a wide range of disciplines such as blockchain, distributed systems, 
            cloud computing and so on.  For a detailed description of my background, please visit the <a href="https://docs.google.com/document/d/16nIfa13pOJqTf6s0ABA6elnCcKJmksHwZRf64qTyPD4/edit?usp=sharing"> following link </a>. <br/>
          </p>
          <p className="text-justify p-2">
            I have availability for hours, days or weeks. I accept bitcoin, etherium and traditional currencies as well. You can find me at  <a href="mailto:info@backendhelpers.co">info@backendhelpers.co</a> 
          </p>
          <div className="text-center p-2 crypto" >
              <div className="p-2 ">
                   <img src={data.btc.childImageSharp.fixed.src} alt="bitcoin" className="img-fluid crypto"/>
                  <img src={ether} alt="etherium" className="crypto-ether"/> 
              </div>

          </div>
        </div>
      </div>


    </LeftColumn>

    <RightColumn {...props}>
      <PostIndex />
    </RightColumn>
  </Blog>)
        }
      }
    />





)

